@media (max-width: $screen-sm-max) {
  header {
    h1 {
      font-size:60px;
      margin-bottom:20px;
    }
    h2 {
      font-size:24px;
      line-height:34px;
      max-width:500px;
    }
   .banner {
     .priceContainer {
       width:230px;
       height:230px;
       span {
         font-size: 20px;
         line-height:39px;
         &.price {
           font-size:45px;
         }
       }
     }
   }
  }
  .navbar-default {
    .container {
      width:100%;
    }
    .navbar-brand {
      max-width:320px;
    }
    .navbar-right {
      li {
        a {
          padding:34px 15px;
          font-size:18px;
        }
      }
    }
  }
  #diensten {
    .container {
      width:100%;
    }
  }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {

}

@media (min-width: $screen-sm-min) {
  header {
    .banner {
      background-position:0 50%;
      background-attachment: fixed;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;

      -webkit-transform: translateZ(-150px) scale(1.1);
      -moz-transform: translateZ(-150px) scale(1.1);
      transform: translateZ(-150px) scale(1.1);
    }

  }
  .innerContainer {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0);
    .innerTable {
      -webkit-transform: translateZ(-150px) scale(.7);
      -moz-transform: translateZ(-150px) scale(.7);
      transform: translateZ(-150px) scale(.7);
    }
  }
}




