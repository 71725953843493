header {
  max-height:100vh;
  overflow:hidden;
  position:relative;
  .banner {
    width:100%;
    height:100vh;
    max-height:100vh;
    overflow:hidden;
    position:relative;
    background:url(/images/header.jpg) no-repeat;
    z-index: 3;

    .priceContainer {
      position:absolute;
      background:rgba(143,140,135, 0.5);
      color:white;
      right:120px;
      bottom:25%;
      border-radius:50%;
      padding:60px 5px 5px 5px;
      width:330px;
      height:330px;
      border:3px solid rgba(255,255,255,0.7);
      span {
        color:white;
        font-size:40px;
        line-height:77px;
        display:block;
        text-align:left;
        font-family: $fontTitle;
        font-weight:300;
        padding-left:66px;

        -webkit-transform: rotate(14deg);
        -moz-transform: rotate(14deg);
        -ms-transform: rotate(14deg);
        -o-transform: rotate(14deg);
        filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
        &.price {
          font-size:77px;
          text-align: center;
          font-family: $fontTitle;
          padding-left:0px;

        }
        &.btw {
          font-size:18px;
          line-height:28px;
          padding-left:0px;
          padding-right:55px;
          text-align:right;
        }
      }
    }
  }

  h1 {
    font-size:108px;
    margin:0 0 30px 0;
    font-weight:700;
  }
  h2 {
    font-size:34px;
    line-height:47px;
    font-weight:400;
    color:$fontColor;
  }
  .btn-primary {
    margin-top:25px;
  }
  .logo-website {
    margin-top:45px;
    margin-left:145px;
    font-size:21px;
    position:relative;
    line-height:34px;
    transform:rotate(-8deg);
    &:before {
      content:"";
      position:absolute;
      left:-75px;
      top:-20px;
      background:url(/images/arrow.png);
      width:69px;
      height:68px;
      transform:rotate(25deg);
    }
    a {
      color:$green;
      text-decoration:none;
      &:hover,
      &:focus {
        color:$orange;
      }
    }
  }
  .innerContainer {
    display:table;
    vertical-align:middle;
    height:100vh;
    position:relative;
    overflow:hidden;

    z-index: 4;
    .innerTable {
      display:table-cell;
      vertical-align:middle;

      padding-bottom:102px;

      z-index: 1;
    }
  }
}

