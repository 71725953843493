* {
  margin:0;
  padding:0;
}

html, body {
  margin: 0;
  padding:0;
  font-size:100%;
}
html {
  height:100%;
}

body {
  font-family: $fontBase;
  font-size:16px;
  color:$fontColor;
  overflow-x:hidden;
  height: 100%;
  min-height:100%;
  /*padding-top:70px;*/
}

.wrapper {
  min-height: 100%;
  height: auto !important;
  margin: 0 auto -186px; }
  .push { height:184px;}


h1, h2, h3 {
  font-family: $fontTitle;
  color:$green;
  text-transform:uppercase;
  font-weight:bold;
  letter-spacing:1px;
}
h1 {
  font-size:54px;
}
h2 {
  font-size:36px;
}
h3 {
  font-size:21px;
  margin-bottom:3px;
}
p, .text ul li {
  line-height:28px;
  font-weight:300;
}
p {
  margin:0 0 25px 0;
  &:last-of-type {
    margin:0;
  }
}

a {
  outline:none;
  color:$orange;
  text-decoration: underline;
  &:hover,
  &:focus {
    outline:none;
    color:$green;
  }
}

section {
  &.padded {
    padding:45px 0;
    h3 {
      &:first-child {
        margin:0;
        padding:0 0 5px 0;
      }
    }
  }
  &.bordered {
      border-top:1px solid $green;
      border-bottom:1px solid $green;
  }
}

//BUTTONS

.btn-primary {
  background:$orange;
  border:0;
  color:#ffffff;
  border-radius:0;
  padding:15px 25px 15px 25px;
  position:relative;
  font-size:18px;
  text-decoration: none;
  border-bottom:3px solid $orangeShadow;
  font-family: $fontRoboto;
  letter-spacing: 1px;
  &:hover,
  &:focus,
  &:active {
    background:$green;
    border-color:$greenShadow;
    text-decoration: none;
    outline:none;
  }
}
.btn-arrow {
  position:relative;
  padding:18px 65px 18px 25px;
  text-transform: uppercase;
  letter-spacing: 1px;
  &:after {
    content: "\f00b";
    font-family: PhfEnYfi;
    font-size: 26px;
    position: absolute;
    right: 25px;
    top: 17px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  &:hover,
  &:focus {
    /*
    &:after {
      top:16px;
      right:16px;
      filter:none;
      ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
      -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg);
    }*/

  }
}
.btn-order,
.btn-info {
  padding:18px 25px 18px 50px;
  position:relative;
  margin-top:30px !important;
  text-transform: uppercase;
  letter-spacing: 1px;

  &:before {
    content: "\f0c9";
    font-family: FontAwesome;
    font-size: 16px;
    position: absolute;
    left: 25px;
    top:19px;
  }
}

.btn-order {
  padding:18px 25px 18px 55px;
  &:before {
    content: "\f07a";
    font-family: FontAwesome;
    font-size: 18px;
    position: absolute;
    left: 25px;
    top:16px;
  }
}

.btn-lg {
  font-size:24px;
  line-height:24px;
}

// SECTIONS
section.white,
section.gray {
  h2 {
    margin:0;
    padding:0 0 5px 0;
  }
  &.space {
    padding-top:135px;
  }
}
section.gray {
  background: $grayLight;
  position:relative;
  &:after,
  &:before {
    content:"";
    width:100%;
    height:18px;
    position:absolute;
    z-index:10;
  }
  &:before {
    left:0;
    top:0;
    -webkit-box-shadow: inset 0px 11px 8px -10px #bbb;
    -moz-box-shadow: inset 0px 11px 8px -10px #bbb;
    box-shadow: inset 0px 11px 8px -10px #bbb;
  }
  &:after {
    left:0;
    bottom:0px;
    -webkit-box-shadow: inset 0px -11px 8px -10px #bbb;
    -moz-box-shadow: inset 0px -11px 8px -10px #bbb;
    box-shadow: inset 0px -11px 8px -10px #bbb;
  }
}
section {
  &:first-of-type {
    padding-top:70px;
  }
}

// VERTICAL ALIGN

.verticalContainer {
  display:table;
  position:relative;
  vertical-align:middle;
  .verticalInner {
    display:table-cell;
    vertical-align:middle;
    .btn-primary {
      margin-top:15px;
    }
  }
}

// SWITCH

.question {
  margin-top:60px;
  h3 {
    margin:0;
    padding:0 0 20px 0;
    color:$fontColor;
    span {
      color:$orange;
    }
  }
  .bootstrap-switch {
    .bootstrap-switch-handle-on.bootstrap-switch-primary {
      background:$orange;
      text-shadow:none;
    }
    .bootstrap-switch-handle-off.bootstrap-switch-primary {
      text-shadow:none;
    }
    .bootstrap-switch-focused {
      border-color:rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25) !important;
    }
    &:focus,
    &:active {
      box-shadow:none;
    }
  }
  .bootstrap-switch.bootstrap-switch-focused {
    border-color:rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25) !important;
    box-shadow:none !important;
  }
}

// MAIN
.text {
  padding-bottom:60px;
}
.tooltip.top .tooltip-inner {
  background-color:$greenShadow;
}
.tooltip.top .tooltip-arrow {
  border-top-color: $greenShadow;
}

#rocket {
  background:#ffffff url(/images/rocket.jpg) no-repeat center right;
  background-attachment: fixed;
  .text {
    padding-bottom:0;
  }
  h2 {
    padding:0 0 5px 0;
  }
}
#img-goedemiddag,
#img-ok2press {
  margin-top:15px;
  float:left;
  display:block;
  padding:0;
  width:100%;
  text-align:center;
}
#img-ok2press {
  margin-top:25px;
  img {
    max-width:260px;
  }
}
#img-goedemiddag {
  img {
    max-width:220px;
  }
}
#img-goedemiddag,
#img-ok2press {
  img {
    margin:0 auto;
    text-align:center;
  }
}


#contact {
  .fa-ul {
    li {
      .fa-li {
        top:6px;
      }
      a {
        color:$fontColor;
        text-decoration:none;
      }
    }
  }
  .block {
    background:$grayLight;
    padding:30px;
    ul {
      margin-bottom:0;
    }
  }
  .form {
    padding-left:30px;
    .form-control {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      height:44px;
      border-radius:0;
      &:focus {
        border-color:$green;
        box-shadow:none;
      }
    }
    textarea.form-control {
      height:132px;
      resize:none;
    }
    .col-sm-8.column {
      padding-right:5px;
    }
    .col-sm-4.column + .col-sm-8.column {
      padding-left:5px;
      padding-right:15px;
    }
  }
}

#map-canvas {
  width:100%;
  height:440px;
}
.alert {
  border-radius:0;
}