@media (max-width: $screen-md-max) {
  .navbar-default {
    .navbar-brand {
      max-width:350px;
      padding-top:20px;
    }
    .navbar-right {
      li {
        a {
          padding:34px 15px;
        }
      }
    }
  }
  .img-example {
    max-width:380px;
    img {
      max-width:100%;
    }
  }
}

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {

}

@media (min-width: $screen-md-min) {

}