#diensten {
  position:absolute;
  top:92px;
}
.nav-diensten {
  float:left;
  width:100%;
  margin:0 0 30px 0;
  padding-top:15px;
  border-top:1px solid $grayLight;
  a {
    float:left;
    &.btn-home {
      border:3px solid $orange;
      border-radius:50%;
      width:44px;
      height:44px;
      font-size:30px;
      line-height: 30px;
      display:block;
      float:left;
      margin:0 15px 0 15px;
      text-align:center;
      padding-top:2px;
      &:hover,
      &:focus {
        border-color:$green;
      }
    }
    &.btn-arrows {
      font-size:42px;
      line-height:42px;
    }
  }
}


.page-title {
  background:$orange;
  h1 {
    color:white;
    padding:16px 0 18px 0;
    margin:0;
    text-transform: uppercase;
    font-size:45px;
    font-weight:bold;
    font-family: $fontTitle;
    position:relative;

    &:after {
      content:"\f0d8";
      font-family: FontAwesome;
      font-size:50px;
      color:white;
      position:absolute;
      left:0;
      bottom:-24px;
    }
  }
}
.specs {
  margin-top:30px;
  margin-bottom:30px;
  float:left;
  width:100%;
  position:relative;
  .table {
    th {
      border:0;
      padding:0 0 10px 0;
      font-size:20px;
      font-weight:normal;
      color:$green;
      font-family: $fontTitle;
      text-transform:uppercase;
    }
    td {
      border:0;
      font-weight:300;
      .fa-check {
        color:$green;
      }
      &:first-child {
        width:350px;
      }
      &:last-child {
        font-weight:600;
      }
    }
  }
  .table-email {
    td:last-child {
      font-weight:300;
    }
  }
}
.specs.package {
  .table {
    td {
      &:first-child {
        width:350px;
      }
    }
  }
}

.breadcrumb {
  background:none;
  color:$grayShadow;
  margin:30px 0;
  padding:0;
  text-decoration: none;
  float:left;
  &:hover,
  &:focus {
    text-decoration: none;
    color:$orange;
  }
}
.text {
  float:left;
  width:100%;
  h1 {
    font-size:36px;
    margin: 0;
    padding: 0 0 5px 40px;
    i {
      font-size: 26px;
      display: inline-block;
      position: absolute;
      top: 6px;
      left: 15px;
    }
    &:first-child {
      margin-top:0;
    }
  }
  p {
    margin:0 0 28px 0;
    font-weight:300;
    &:last-child {
      margin:0;
    }
  }
  &.description {
    padding-bottom:0px;
  }
}
.space {
  .text {
    h1 {
      padding: 0 0 5px 0;
    }
  }
}

.detail-page {
  margin-top:239px;
  .container {
    position:relative;
    z-index:10;
  }
  &.detail-logo {
    position:relative;
    background:url(/images/detail/logo.jpg) no-repeat;
    background-position: center right;
    background-size: 30%;
  }
  &.detail-visitekaartjes {
    position:relative;
    background:url(/images/home/visitekaartjes.jpg) no-repeat;
    background-position: center right;
  }
  &.detail-enveloppen {
    position:relative;
    background:url(/images/detail/enveloppen.jpg) no-repeat;
    background-position: center right;
    background-size:35%;
  }
  &.detail-briefpapier {
    position:relative;
    background:url(/images/detail/briefpapier.jpg) no-repeat;
    background-position: center right;
    background-size:40%;
  }
  &.detail-website {
    position:relative;
    /*
    background:url(/images/detail/website.jpg) no-repeat;
    background-position: center right;
    background-size:35%;
    */

    .table-website {
      i {
        &.fa-check {
          color:$green !important;
        }
        &.fa-close {
          color:#ae1d18 !important;
        }
      }
    }
  }
  &.detail-email {
    position:relative;
    background:url(/images/detail/email.jpg) no-repeat;
    background-size:35%;
    background-position: center right;
    .table-email {
      th {
        text-align:center;
        &:first-child {
          text-align:left;
        }
      }
      td {
        text-align:center;
        &:first-child {
          text-align:left;
        }
        i {
          &.fa-check {
            color:$green;
          }
          &.fa-close {
            color:#ae1d18;
          }
        }
        &:last-child {
          color:$fontColor;
        }
      }
    }
  }
}

.example {
  position:absolute;
  right:0;
  top:50%;
  transform:translateY(-50%);
  z-index:11;
  padding:0;
  margin-top:0px;
  background:white;
  a {
    display:block;
  }
  a.btn-view {
    text-align:center;
    text-decoration: none;
    position:absolute;
    bottom:-80px;
    left:50%;
    transform: translateX(-50%);
    color:$orange;
    padding-left:32px;
    display:inline;
    font-size:20px;
    text-transform:uppercase;
    font-family: $fontRoboto;
    &:before {
      content:"\f00e";
      font-family: FontAwesome;
      left:0;
      top:-6px;
      position:absolute;
      font-size:28px;
    }
    &:hover,
    &:focus {
      color:$green;
      &:before {
        color:$green;
      }
    }
  }
}