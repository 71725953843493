footer {
  padding:40px 0 0 0;
  background:$green;
  color:$greenShadow;
  height:185px;
  position:relative;
  z-index:100;
  h3 {
    color:$greenShadow;
    margin:0;
    padding:0 0 8px 0;
    font-size:21px;
    font-weight:bold;
  }
  a {
    color:$greenShadow;
    text-decoration:none;
    &:hover,
    &:focus {
      color:$greenShadow;
    }
  }
  ul {
    &.links {
      margin:0;
      list-style:none;
      width:100%;
      float:right;
      li {
        float:left;
        margin:0 8px 0 0;
        a {
          padding:8px 5px;
          width:55px;
          height:55px;
          text-align:center;
          margin:0 auto;
          display:block;
          border:3px solid $greenShadow;
          border-radius:50%;
          color:$greenShadow;
          text-decoration:none;
          -ms-transform: rotate(0deg);
          -webkit-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          -moz-transform:rotate(0deg);
          transform: rotate(0deg);
          -webkit-transition: .5s;
          transition: .5s;

          i {
            font-size:24px;
            margin-top:4px;
          }
          &:hover,
          &:focus {
            border:3px solid #ffffff;
            -ms-transform: rotate(360deg);
            -webkit-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            -moz-transform:rotate(360deg);
            transform: rotate(360deg);
            i {
              color:white;
            }
          }
        }
        &:last-child {
          margin:0;
        }
      }
    }
  }
  .bottom {
    background:$greenShadow;
    float:left;
    width:100%;
    h3 {
      margin:0;
      padding:7px 0 7px 0;
      font-size:16px;
      font-weight:normal;
      color:$green;
      width:100%;
      text-align:center;
    }
  }
}