@media (max-width: $screen-xs-max) {
  .text h1 {
    font-size:30px;
  }
  h2 {
    font-size:22px;
  }
  .navbar-fixed-top {
    background:white;
    .navbar-header {
      .navbar-brand {
        max-width: 230px;
        padding-top: 14px;
        img {
          max-width: 100%;
        }
      }
      button {
        background: transparent;
        border: 0;
        float: right;
        width: 50px;
        height: 35px;
        position: relative;
        margin: 14px 15px 6px 15px;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
        cursor: pointer;
        span {
          display: block;
          position: absolute;
          height: 4px;
          width: 100%;
          background: $green;
          border-radius: 9px;
          opacity: 1;
          left: 0;
          -webkit-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
          -webkit-transition: .25s ease-in-out;
          -moz-transition: .25s ease-in-out;
          -o-transition: .25s ease-in-out;
          transition: .25s ease-in-out;
        }
        span:nth-child(1) {
          top: 0;
        }

        span:nth-child(2) {
          top: 12px;
        }

        span:nth-child(3) {
          top: 24px;
        }
      }
      .menu-toggle.open span:nth-child(1) {
        top: 12px;
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        transform: rotate(135deg);
      }

      .menu-toggle.open span:nth-child(2) {
        opacity: 0;
        left: -60px;
      }

      .menu-toggle.open span:nth-child(3) {
        top: 12px;
        -webkit-transform: rotate(-135deg);
        -moz-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        transform: rotate(-135deg);
      }
    }
    #navbar {
      .navbar-right {
        margin:0 -15px;
        li {
          border-bottom:1px solid #eee;
          a {
            padding:15px;
            text-decoration: none;
            &:hover,
            &:focus {
              text-decoration: none;
            }
          }
          &.active {
            a {
              background:$green;
              color:white;
            }
          }
        }
      }
    }

  }

  header {
    .banner {
      background-position: center center;
      background-size: cover;
      width: 100%;
      .priceContainer {
        left: 15px !important;
        right:0;
        width: 190px;
        height: 190px;
        top: 290px;
        bottom: 0;
        padding-top: 40px;
      }
    }
    .innerContainer {
      .innerTable {
        display: block;
        padding-top: 115px;
        padding-bottom: 0;
        h1 {
          font-size: 40px;
          margin-bottom: 0;
        }
        h2 {
          font-size: 16px;
          margin-top: 8px;
        }
      }
    }
    .btn-primary {
      margin-top: 5px;
      position: absolute;
      bottom: 10vh;
      width: 100%;
    }
  }
  section.homeBriefpapier,
  section.homeEmail,
  section.homeLogo {
    .verticalContainer {
      height:auto;
      display:block;
      margin:15px 0;
      .verticalInner {
        background: rgba(255, 255, 255, 0.8);
        padding: 15px;
        display: block;
      }
    }
  }
  .homeEnveloppen,
  .homeVisitekaartjes,
  .homeWebsite {
    .verticalContainer {
      height:auto;
      display:block;
      padding:15px 0;
    }
  }

  .btn-info {
    max-width:100%;
    width:100%;
    font-size:16px;
    padding-left:10px;
    padding-right:10px;
    &:before {
      content:""
    }
  }

  .navCategories {
    display: none;
  }

  #rocket {
    overflow-x:hidden;
    .container {
      padding:0;
      .text {
        background:rgba(255,255,255,0.8);
        padding:15px;
      }
    }

  }

  section.white.space,
  section.gray.space {
    padding-top:60px;
  }
  section.padded {
    padding:30px 0;
  }

  #contact {
    .block {
      padding:15px 0 30px 0;
      background:white;
      border:0;
      h2 {
        font-size: 30px;
        margin: 0;
        padding: 0 0 5px 0;
      }
    }
    .form {
      padding-left:0;
      padding-bottom:0;
      .btn-primary {
        width:100%;
      }
    }
  }
  .detail-page {
    margin-top:75px;
    &.detail-email,
    &.detail-visitekaartjes,
    &.detail-briefpapier,
    &.detail-logo,
    &.detail-enveloppen,
    &.detail-website {
      background:none;
    }
  }
  .specs {
    padding:0px;
    p {
      padding:15px;
    }
    .table {
      thead {
        tr {
          th {
            padding:5px;
          }
        }
      }
    }
    .btn-order {
      margin:15px;
    }
  }

  footer {
    height:auto;
    padding:30px 0 0 0;
    ul.links {
      float:left;
      margin:15px 0 30px 0;
      li {
        a {
          width:40px;
          height:40px;
          padding:7px 5px;
          i {
            font-size:18px;
            margin-top:0;
          }
        }
      }
    }
    .pull-right {
      float:left !important;
    }
  }

}