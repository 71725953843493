.navCategories {
  background: $orange;
  color:white;
  width:100%;
  height:102px;
  left:0;
  bottom:0;
  position:absolute;
  z-index:999;
  padding-top:0 !important;
  .nav-justified {
    width:100%;
    float:left;
    display:block;
    li {
      width:16.66666667%;
      float:left;
      border-left:1px solid #ffffff !important;

      &:last-child {
        border-right:1px solid #ffffff;
      }

      a {
        color:#ffffff;
        font-family: $fontRoboto;
        font-size:16px;
        padding:25px 0;
        height:102px;
        width:100%;
        text-transform:uppercase;
        text-align:center;
        text-decoration: none;
        letter-spacing: 1px;

        display: inline-block;
        vertical-align: middle;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        position: relative;
        background: $green;
        -webkit-transition-property: color;
        transition-property: color;
        -webkit-transition-duration: 0.2s;
        transition-duration: 0.2s;

        i {
          display:block;
          width:100%;
          font-size:28px;
          -webkit-transition: all 0.4s ease;
          -moz-transition: all 0.4s ease;
          -o-transition: all 0.4s ease;
          -ms-transition: all 0.4s ease;
          transition: all 0.4s ease;
        }
        span {
          display:block;
          width:100%;
          padding-top:6px;
        }

        &:before {
          content: "";
          position: absolute;
          z-index: -1;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: $orange;
          -webkit-transform: scale(1);
          transform: scale(1);
          -webkit-transition-property: transform;
          transition-property: transform;
          -webkit-transition-duration: 0.2s;
          transition-duration: 0.2s;
          -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out;
        }
        &:hover,
        &:focus,
        &:active {
          color:white;

          i {
            -ms-transform: rotate(-25deg);
            -webkit-transform: rotate(-25deg);
            transform: rotate(-25deg);
          }

          &:before {
            -webkit-transform: scale(0);
            transform: scale(0);
          }
        }
      }
      &:last-child {
        a {
          i {
            font-size:26px;
          }
          span {
            padding-top:7px;
          }
        }
      }
    }
  }
  &.fixed {
    position:fixed;
    top:92px;
    width:100%;
    z-index:100;
  }
}

// LOGO

section.homeLogo {
  margin-top:0px;
  background:$grayLight url(/images/home/logo.jpg) no-repeat;
  background-position:center right;
  background-attachment: fixed;
  .verticalContainer {
    height:525px;
  }
}

// WEBSITE

.homeWebsite {
  .img-website {
    padding-right:30px;
  }
  .verticalContainer {
    height:625px;
  }
}

// EMAIL

section.homeEmail {
  background:$grayLight url(/images/home/email.png) no-repeat;
  background-position:right center;
  background-attachment: fixed;
  .verticalContainer {
    height:525px;
  }
}

// VISITEKAARTJES

.homeVisitekaartjes {
  .verticalContainer {
    height:625px;
  }
}

// BRIEFPAPIER

section.homeBriefpapier {
  background:$grayLight url(/images/home/briefpapier.png) no-repeat;
  background-position:right center;
  background-attachment: fixed;

  .verticalContainer {
    height:525px;
  }
}


// ENVELOPPEN

.homeEnveloppen {
  .verticalContainer {
    height:625px;
  }
}