@media only screen and (min-width: 768px) {
  .menu-toggle {
    display:none;
  }
  .navbar-default {
    background: white;
    border-radius: 0;
    border: 0px solid white;
    border-bottom:1px solid #eee;
    margin-bottom: 0px;
    min-height:92px;
    .navbar-brand {
      position: relative;
      max-width:400px;
      .logo {
        max-width:100%;
        display:block;
      }
    }
    .navbar-right {
      li {
        margin-left: 0;
        a {
          color: $gray;
          font-size: 22px;
          padding:34px 15px;
          height:92px;
          border-radius: 0;
          border-right: 1px solid #eee;
          text-transform: uppercase;
          letter-spacing:1px;
          text-decoration: none;
          font-family: 'Roboto Condensed', sans-serif;
          &:hover,
          &:focus {
            background: $green;
            color: white;
            border-radius: 0;
          }
        }
        &.active {
          a,
          a:hover,
          a:focus {
            background: $green;
            color: white;
          }
        }
        &:first-child {
          a {
            border-left: 1px solid #eee;
          }
        }
        &:last-child {
          a {
            position: relative;
            padding-left: 45px;
            color: #365ba9;
            &:before {
              content: "\f07a";
              font-family: FontAwesome;
              color: #365ba9;
              left: 15px;
              top: 33px;
              position: absolute;
            }
            &:hover,
            &:focus {
              background: #365ba9;
              color: white;
              &:before {
                color: white;
              }
            }
          }
        }
      }
    }
    &.shadow {
      -webkit-box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.2);
      box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.2);
    }
  }
}