@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)  {
  header {
    h1 {
      font-size:80px;
      margin-bottom:0;
    }
    h2 {
      font-size:27px;
      margin-top:5px;
    }
    .btn-primary {
      margin-top:25px;
    }
    .banner {
      background-position:0 0;
      background-attachment: scroll;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;

      -webkit-transform: none;
      -moz-transform: none;
      -ms-transform: none;
      -o-transform: none;
      transform: none;
      .priceContainer {
        width:225px;
        height:225px;
        right:75px;
        padding-top:52px;
        span {
          font-size:24px;
          line-height:37px;
        }
        span.price {
          font-size:50px;
          line-height:50px;
        }
      }
    }
  }
  .innerContainer {
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
    .innerTable {
      -webkit-transform: none;
      -moz-transform: none;
      -ms-transform: none;
      -o-transform: none;
      transform: none;
    }
  }

  .navCategories.fixed {
    width:100% !important;
    background:$orange !important;
  }
}

@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : portrait) {
  .navbar-default .navbar-brand {
    max-width: 290px;
    padding-top: 30px;
  }
  #contact {
    .block {
      margin:0 0 30px 0;
    }
    .form {
      padding-left:0;
    }
  }
  section.homeBriefpapier,
  section.homeEmail,
  section.homeVisitekaartjes,
  section.homeEnveloppen,
  section.homeWebsite,
  section.homeLogo {
    .verticalContainer {
      height:auto;
      padding:45px 0;
    }
  }
  footer {
    height:auto;
    .pull-right {
      float:left !important;
      ul.links {
        margin:0 0 30px 0;
      }
    }
  }
}