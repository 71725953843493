
@media (min-width: $screen-lg-min) {
  .navbar-default {
    .navbar-right {
      li {
        a {
          padding:34px 25px;
        }
      }
    }
  }
}

@media (max-width:1399px) {
  .img-example {
    max-width:480px;
    img {
      max-width:100%;
    }
  }
}