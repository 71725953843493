$green:               #05948e;
$greenShadow:         #035e5a;

$orange:              #f4a100;
$orangeShadow:        #c17f00;

$gray:                #aaaeb0;
$grayLight:           #eeeeee;
$grayShadow:          #a1a5a7;

$fontColor:           #62625f;

$fontBase:            'Open Sans', sans-serif;
$fontTitle:           "adelle-sans";
$fontRoboto:          'Roboto Condensed', sans-serif;


// RESPONSIVE

$screen-xs: 480px !default;
$screen-xs-min: $screen-xs !default;

$screen-sm: 768px !default;
$screen-sm-min: $screen-sm !default;

$screen-md: 992px !default;
$screen-md-min: $screen-md !default;

$screen-lg: 1200px !default;
$screen-lg-min: $screen-lg !default;

$screen-xs-max: ($screen-sm-min - 1) !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;